import React from 'react'
import { Link } from 'gatsby'

import { css } from '@emotion/core'

import Layout from '../components/layout'

import SignInWithGithubButton from 'components/buttons/github'
import AddToSlackButton from 'components/buttons/add-to-slack'

const StyleGuidePage = () => (
  <Layout>
    <div
      css={css`
        width: 60vw;
      `}
    >
      <h1>H1. Header</h1>
      <p
        css={css`
          color: #333;
        `}
      >
        Longer description for paragraphs. It's useful to see it in context and
        therefore we are adding a lenghy text here. How lenghy is enough? Well
        it's quite phylosophical question, and we are not yet sure 🙂
      </p>
      <Link to="">Link to nothing</Link>
      <div />

      <SignInWithGithubButton text="Add to Github" />
      <div />
      <AddToSlackButton size={60} />
    </div>
  </Layout>
)

export default StyleGuidePage
